import { couch_uuid, id, index } from './fields'

export const schema = {
	title: 'threatment schema',
	description: 'describes a single threatment',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		remote_id: {
			...index,
			...id,
		},
		title: {
			type: 'string',
		},
		form: {
			type: ['object', 'null'],
		},
		is_editable_cn: {
			type: 'boolean',
		},
	},
	required: ['couch_uuid'],
	indexes: [
		['remote_id'],
	],
}

export default schema
