import { couch_uuid, id, index } from './fields'

import { getAsBool } from '@/functions/env'

const VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED = getAsBool(
	'VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED'
)

const getIndexes = function () {
	let indexes = [
		['stay_id_ref', 'creation_date']
	]

	if (VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED) {
		indexes = [
			...indexes,
			['hospitalisation_id_ref', 'creation_date']
		]
	}

	return indexes
}

export const schema = {
	title: 'threatment schema',
	description: 'describes a single threatment',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
			...index,
		},

		author_name: {
			type: 'string',
		},
		creation_date: {
			...index,
			type: 'string',
		},
		modification_date: {
			type: ['string', 'null'],
		},
		name: {
			...index,
			type: 'string',
		},
		name_code: {
			type: 'string',
		},
		form_id_ref: { ...id },
		form: {
			// type: 'string', //TODO type: 'object'
			type: ['object', 'null'],
		},
		stay_orgunit_code: {
			type: 'string',
		},
		hospitalisation_id_ref: {
			...id,
		},
		is_signed: {
			type: 'boolean',
		},
		is_sign_rejected: {
			type: 'boolean',
		},
		signed_date: {
			type: ['string', 'null'],
		},
		signed_file_hash: {
			type: ['string', 'null'],
		},
		is_signable: {
			type: 'boolean',
		},
		is_editable_cn: {
			type: ['boolean', 'null'],
		},
			
		remote_id: {
			...id,
		},
		template_remote_id_ref: {
			...id,
		}
	},
	required: ['couch_uuid'],
	indexes: getIndexes(),
}

export default schema
