import { couch_uuid, id, index } from './fields'

// import { getAsBool } from '@/functions/env'

// const VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED = getAsBool(
// 	'VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED'
// )

// const getIndexes = function () {
// 	let indexes = [
// 		['stay_id_ref', 'examination_date'],
// 		['stay_id_ref', 'order_date'],
// 	]

// 	if (VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED) {
// 		indexes = [
// 			...indexes,
// 			['hospitalisation_id_ref', 'examination_date'],
// 			['hospitalisation_id_ref', 'order_date'],
// 		]
// 	}

// 	return indexes
// }

export const schema = {
	title: 'examination request schema',
	description: 'describes a single examination request',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
		},

		gui_type: {
			type: ['string', 'null'],
		},
		order_date: {
			...index,
			type: 'string',
		},
		performing_unit_gui_type: {
			type: ['string', 'null'],
		},
		service_id_ref: {
			...id,
		},
		service_name: {
			type: 'string',
		},
		status_name: {
			type: ['string', 'null'],
		},
		
		hospitalisation_id_ref: {
			...id,
		},
			
		remote_id: {
			...id,
		},
		
		person_id_ref: {
			...id,
		},
		
		performing_unit_uuid: couch_uuid,
		
		status: {
			type: 'string',
		},
	},
	required: [
		'couch_uuid', 
		'person_id_ref', 
		'stay_id_ref', 
		'hospitalisation_id_ref', 
		'service_id_ref', 
		'performing_unit_uuid',
		'status',
	],
	// indexes: getIndexes(),
}

export default schema
