import { couch_uuid, id } from './fields'

export const schema = {
	title: 'diagnose schema',
	description: 'describes a single diagnose',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,

		name: {
			type: 'string',
		},
		code: {
			type: 'string',
		},
		type_remote_id: {
			...id,
		},
		
		id: id,
	},
}

export default schema
