import { couch_uuid } from './fields'

export const schema = {
	title: 'org units',
	description: 'describes a unit',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		performing_unit_uuid: couch_uuid,
		service_uuid: couch_uuid
	},
}

export default schema
